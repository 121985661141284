var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "landInfoForm-container" },
    [
      _vm.pageType !== "check"
        ? _c(
            "form-panel",
            _vm._b(
              {
                ref: "formPanel",
                staticClass: "form-container",
                attrs: {
                  form: _vm.form,
                  submitUrl: _vm.submitUrl,
                  submitText: "保存",
                  submitBefore: _vm.submitBefore,
                  submitSuccess: _vm.submitSuccess,
                },
              },
              "form-panel",
              _vm.submitConfig,
              false
            ),
            [
              _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "新增土地信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属项目",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属项目",
                                trigger: "blur",
                              },
                            ],
                            prop: "communityId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: { width: 200, placeholder: "查询项目" },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.communityParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "地块代码",
                            rules: [
                              {
                                required: true,
                                message: "请输入地块代码",
                                trigger: "blur",
                              },
                            ],
                            prop: "blockOfCode",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              width: 200,
                              placeholder: "请输入地块代码",
                            },
                            model: {
                              value: _vm.form.blockOfCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "blockOfCode", $$v)
                              },
                              expression: "form.blockOfCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "地块名称",
                            rules: [
                              {
                                required: false,
                                message: "请输入地块名称",
                                trigger: "blur",
                              },
                            ],
                            prop: "siteName",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              width: 200,
                              placeholder: "请输入地块名称",
                            },
                            model: {
                              value: _vm.form.siteName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "siteName", $$v)
                              },
                              expression: "form.siteName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "地块类别",
                            rules: [
                              {
                                required: true,
                                message: "请选择地块类别",
                                trigger: "blur",
                              },
                            ],
                            prop: "plotCategory",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { width: 200, options: _vm.landType },
                            model: {
                              value: _vm.form.plotCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "plotCategory", $$v)
                              },
                              expression: "form.plotCategory",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "确权面积",
                            rules: [
                              {
                                required: true,
                                trigger: "blur",
                                validator: _vm.madeArea,
                              },
                            ],
                            prop: "madeByArea",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.madeByAreaHectare,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "madeByAreaHectare",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.madeByAreaHectare",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("公顷"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.madeByAreaMu,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "madeByAreaMu",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.madeByAreaMu",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("亩"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.madeByAreaFen,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "madeByAreaFen",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.madeByAreaFen",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("分"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.madeByAreaLi,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "madeByAreaLi",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.madeByAreaLi",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("厘"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.madeByAreaHao,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "madeByAreaHao",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.madeByAreaHao",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("毫"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "实测面积",
                            rules: [
                              {
                                required: false,
                                message: "请输入实测面积",
                                trigger: "blur",
                              },
                            ],
                            prop: "measuredAreaHao",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.measuredAreaHectare,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "measuredAreaHectare",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.measuredAreaHectare",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("公顷"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.measuredAreaMu,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "measuredAreaMu",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.measuredAreaMu",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("亩"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.measuredAreaFen,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "measuredAreaFen",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.measuredAreaFen",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("分"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.measuredAreaLi,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "measuredAreaLi",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.measuredAreaLi",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("厘"),
                          ]),
                          _c("v-input-number", {
                            attrs: { precision: 2, width: 60 },
                            model: {
                              value: _vm.form.measuredAreaHao,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "measuredAreaHao",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.measuredAreaHao",
                            },
                          }),
                          _c("span", { staticClass: "area-ruler" }, [
                            _vm._v("毫"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "坐落",
                            rules: [
                              {
                                required: true,
                                trigger: "blur",
                                validator: _vm.located,
                              },
                            ],
                            prop: "beLocated",
                          },
                        },
                        [
                          _c("v-input", {
                            staticClass: "area-item area-bot",
                            attrs: { placeholder: "东至" },
                            model: {
                              value: _vm.form.beLocatedEast,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "beLocatedEast",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.beLocatedEast",
                            },
                          }),
                          _c("v-input", {
                            staticClass: "area-item area-bot",
                            attrs: { placeholder: "西至" },
                            model: {
                              value: _vm.form.beLocatedWest,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "beLocatedWest",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.beLocatedWest",
                            },
                          }),
                          _c("br"),
                          _c("v-input", {
                            staticClass: "area-item area-bot",
                            attrs: { placeholder: "南至" },
                            model: {
                              value: _vm.form.beLocatedSouth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "beLocatedSouth",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.beLocatedSouth",
                            },
                          }),
                          _c("v-input", {
                            staticClass: "area-item area-bot",
                            attrs: { placeholder: "北至" },
                            model: {
                              value: _vm.form.beLocatedNorth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "beLocatedNorth",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.beLocatedNorth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "posStyle",
                          attrs: {
                            label: "土地经纬度",
                            rules: [{ required: false }],
                            prop: "longitude",
                          },
                        },
                        [
                          _c("v-input", {
                            staticClass: "area-item",
                            attrs: { placeholder: "经度", width: _vm.width },
                            model: {
                              value: _vm.form.longitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "longitude", $$v)
                              },
                              expression: "form.longitude",
                            },
                          }),
                          _c("v-input", {
                            staticClass: "area-item",
                            attrs: { placeholder: "纬度", width: _vm.width },
                            model: {
                              value: _vm.form.latitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "latitude", $$v)
                              },
                              expression: "form.latitude",
                            },
                          }),
                          _c("v-button", {
                            attrs: { text: "地图标注" },
                            on: { click: _vm.getMap },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "posStyle",
                          attrs: { label: "土地照片", prop: "pic" },
                        },
                        [
                          _c(
                            "v-uploader",
                            {
                              ref: "myUpload",
                              attrs: {
                                action: _vm.uploadImageURL,
                                imgUrls: _vm.form.picUrl,
                                onSuccess: _vm.onSuccess,
                              },
                              on: {
                                "update:imgUrls": function ($event) {
                                  return _vm.$set(_vm.form, "picUrl", $event)
                                },
                                "update:img-urls": function ($event) {
                                  return _vm.$set(_vm.form, "picUrl", $event)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "tip" }, slot: "tip" },
                                [
                                  _vm._v(
                                    "注：图片要求：宽180px高120px，图片小于512KB"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "check-wrapper" },
            [
              _c("v-button", {
                staticClass: "check-back",
                attrs: { text: "返回", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$router.back()
                  },
                },
              }),
              _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "新增土地信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 15,
                          label: "所属项目",
                          text: _vm.form.communityName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: { label: "地块名称", text: _vm.form.siteName },
                      }),
                      _c("col2-item", {
                        attrs: { label: "面积", text: _vm.form.madeByArea },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "坐落(四至)",
                          text: _vm.form.beLocated,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "地块代码",
                          text: _vm.form.blockOfCode,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "地块类型",
                          text: _vm.landTypeMap[_vm.form.plotCategory],
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "col2-block",
                    { attrs: { title: "确权信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "户主",
                          text: _vm.form.userName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "手机号码",
                          text: _vm.form.mobileNum,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包家庭",
                          text: _vm.form.houseAddress,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "发包方",
                          text: _vm.form.contractor,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包方式",
                          text: _vm.contractorTypeMap[
                            Number(_vm.form.contractorType)
                          ],
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "合同代码",
                          text: _vm.form.contractCode,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包期限",
                          text: `${
                            _vm.form.contractBeginTime
                              ? _vm.form.contractBeginTime + " 至 "
                              : ""
                          }${_vm.form.contractEndTime}`,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包总面积（亩）",
                          text: _vm.form.contractArea,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包总块数（块）",
                          text: _vm.form.contractNum,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "创建时间",
                          text: _vm.form.intime,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("v-map", {
        attrs: {
          selectedLng: Number(_vm.form.longitude),
          selectedLat: Number(_vm.form.latitude),
          showMapDialog: _vm.showMapDialog,
        },
        on: {
          "update:showMapDialog": function ($event) {
            _vm.showMapDialog = $event
          },
          "update:show-map-dialog": function ($event) {
            _vm.showMapDialog = $event
          },
          setLngAndLat: _vm.setLngAndLat,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }