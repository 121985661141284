<template>
  <div class="landInfoForm-container">
    <form-panel v-if="pageType !== 'check'" class="form-container" ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <col2-detail>
        <col2-block title="新增土地信息">
          <el-form-item label="所属项目" :rules="[{ required: true, message: '请选择所属项目', trigger: 'blur' }]" prop="communityId">
            <v-select2 :width="200" v-model="form.communityId" v-bind="communityParams" placeholder="查询项目"/>
          </el-form-item>
          <el-form-item label="地块代码" :rules="[{ required: true, message: '请输入地块代码', trigger: 'blur' }]" prop="blockOfCode">
            <v-input :width="200" placeholder="请输入地块代码" v-model="form.blockOfCode"></v-input>
          </el-form-item>
          <el-form-item label="地块名称" :rules="[{ required: false, message: '请输入地块名称', trigger: 'blur' }]" prop="siteName">
            <v-input :width="200" placeholder="请输入地块名称" v-model="form.siteName"></v-input>
          </el-form-item>
          <el-form-item label="地块类别" :rules="[{ required: true, message: '请选择地块类别', trigger: 'blur' }]" prop="plotCategory">
            <v-select :width="200" v-model="form.plotCategory" :options="landType"></v-select>
          </el-form-item>
          <el-form-item label="确权面积" :rules="[{ required: true, trigger: 'blur', validator: madeArea }]" prop="madeByArea">
            <v-input-number :precision="2" :width="60" v-model.trim="form.madeByAreaHectare"></v-input-number><span class="area-ruler">公顷</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.madeByAreaMu"></v-input-number><span class="area-ruler">亩</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.madeByAreaFen"></v-input-number><span class="area-ruler">分</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.madeByAreaLi"></v-input-number><span class="area-ruler">厘</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.madeByAreaHao"></v-input-number><span class="area-ruler">毫</span>
          </el-form-item>
          <el-form-item label="实测面积" :rules="[{ required: false, message: '请输入实测面积', trigger: 'blur' }]" prop="measuredAreaHao">
            <v-input-number :precision="2" :width="60" v-model.trim="form.measuredAreaHectare"></v-input-number><span class="area-ruler">公顷</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.measuredAreaMu"></v-input-number><span class="area-ruler">亩</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.measuredAreaFen"></v-input-number><span class="area-ruler">分</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.measuredAreaLi"></v-input-number><span class="area-ruler">厘</span>
            <v-input-number :precision="2" :width="60" v-model.trim="form.measuredAreaHao"></v-input-number><span class="area-ruler">毫</span>
          </el-form-item>
          <el-form-item label="坐落" :rules="[{ required: true, trigger: 'blur', validator: located }]" prop="beLocated">
            <v-input class="area-item area-bot" v-model.trim="form.beLocatedEast" placeholder="东至"></v-input>
            <v-input class="area-item area-bot" v-model.trim="form.beLocatedWest" placeholder="西至"></v-input><br>
            <v-input class="area-item area-bot" v-model.trim="form.beLocatedSouth" placeholder="南至"></v-input>
            <v-input class="area-item area-bot" v-model.trim="form.beLocatedNorth" placeholder="北至"></v-input>
          </el-form-item>
          <el-form-item class="posStyle" label="土地经纬度" :rules="[{ required: false }]" prop="longitude">
            <v-input class="area-item" v-model="form.longitude" placeholder="经度" :width="width"></v-input>
            <v-input class="area-item" v-model="form.latitude" placeholder="纬度" :width="width"></v-input>
            <v-button text="地图标注" @click="getMap"></v-button>
          </el-form-item>
          <el-form-item class="posStyle" label="土地照片" prop="pic">
            <v-uploader
              ref="myUpload"
              :action="uploadImageURL"
              :imgUrls.sync="form.picUrl"
              :onSuccess="onSuccess"
              >
                <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
            </v-uploader>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <div class="check-wrapper" v-else>
      <v-button class="check-back" text="返回" type="primary" @click="$router.back()"></v-button>
      <col2-detail>
        <col2-block title="新增土地信息">
          <col2-item :span="15" label="所属项目" :text="form.communityName" />
          <col2-item label="地块名称" :text="form.siteName" />
          <col2-item label="面积" :text="form.madeByArea" />
          <col2-item label="坐落(四至)" :text="form.beLocated" />
          <col2-item label="地块代码" :text="form.blockOfCode" />
          <col2-item label="地块类型" :text="landTypeMap[form.plotCategory]" />
        </col2-block>
        <!-- <hr> -->
        <col2-block title="确权信息">
          <col2-item :span="24" label="户主" :text="form.userName" />
          <col2-item :span="24" label="手机号码" :text="form.mobileNum" />
          <col2-item :span="24" label="承包家庭" :text="form.houseAddress" />
          <col2-item :span="24" label="发包方" :text="form.contractor" />
          <col2-item :span="24" label="承包方式" :text="contractorTypeMap[Number(form.contractorType)]" />
          <col2-item :span="24" label="合同代码" :text="form.contractCode" />
          <col2-item :span="24" label="承包期限" :text="`${form.contractBeginTime ? form.contractBeginTime + ' 至 ': ''}${form.contractEndTime}`" />
          <col2-item :span="24" label="承包总面积（亩）" :text="form.contractArea" />
          <col2-item :span="24" label="承包总块数（块）" :text="form.contractNum" />
          <col2-item :span="24" label="创建时间" :text="form.intime" />
        </col2-block>
      </col2-detail>
    </div>
    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>
<script>
import _ from 'lodash'
import { landType, landTypeMap, contractorTypeMap } from './map'
import { Col2Detail, Col2Block, Col2Item, CheckboxPlus, MultiSelect } from 'components/bussiness'
import { vMap, vUploader } from 'components/control'
import { communityParams } from 'common/select2Params'
import { uploadImageURL, getLandInfo, submitUrl } from './api'
export default {
  name: 'landInfo',
  components: {
    CheckboxPlus,
    MultiSelect,
    Col2Detail,
    Col2Block,
    Col2Item,
    vMap,
    vUploader
  },
  data () {
    return {
      communityParams,
      uploadImageURL,
      lwidth: 300,
      width: 150,
      landPicList: [],
      submitConfig: {},
      submitUrl: submitUrl,
      form: {
        communityId: '',
        blockOfCode: '',
        siteName: '',
        plotCategory: undefined,

        madeByAreaHectare: undefined,
        madeByAreaMu: undefined,
        madeByAreaFen: undefined,
        madeByAreaLi: undefined,
        madeByAreaHao: undefined,

        measuredAreaHectare: undefined,
        measuredAreaMu: undefined,
        measuredAreaFen: undefined,
        measuredAreaLi: undefined,
        measuredAreaHao: undefined,

        beLocatedEast: '',
        beLocatedWest: '',
        beLocatedSouth: '',
        beLocatedNorth: '',

        latitude: '',
        longitude: '',

        picUrl: ''
      },
      showMapDialog: false,
      landType,
      landTypeMap,
      contractorTypeMap,
      pageType: '', // check: 查看  add: 新增  edit: 编辑
      area: '',
      location: ''
    }
  },
  mounted () {
    const { id, type } = this.$route.query
    this.pageType = type
    console.log(id, type)
    if (type !== 'add') {
      this.handleGetLandFormData(id)
    }
  },
  methods: {
    submitBefore (data) {
      if (!data.id) {
        data.id = ''
      }
      data.madeByAreaHectare === undefined ? data.madeByAreaHectare = 0 : null
      data.madeByAreaMu === undefined ? data.madeByAreaMu = 0 : null
      data.madeByAreaFen === undefined ? data.madeByAreaFen = 0 : null
      data.madeByAreaLi === undefined ? data.madeByAreaLi = 0 : null
      data.madeByAreaHao === undefined ? data.madeByAreaHao = 0 : null
      return data
    },
    // 提交成功回调
    submitSuccess () {
      if (this.pageType === 'add') {
        this.$message.success('新增成功！')
      } else {
        this.$message.success('修改成功！')
      }
      this.$router.back()
    },
    getMap () {
      this.showMapDialog = true
    },
    setLngAndLat (val) {
      this.form.longitude = val.lng
      this.form.latitude = val.lat
      this.showMapDialog = false
    },
    itude (rule, value, callback) {
      if (!this.form.longitude) {
        callback(new Error('项目经度不能为空'))
      } else if (!this.form.latitude) {
        callback(new Error('项目纬度不能为空'))
      } else {
        callback()
      }
    },
    // 上传成功回调
    onSuccess (val) {
      console.log(this.landPicList);
      console.log(val.data);
      this.form.picUrl = val.data[0]
    },
    /**
     * @description: 根据id获取内容数据
     * @param {Number} id
     * @return {void}
     */    
    async handleGetLandFormData (id) {
      const params = { id }
      const res = await this.$axios.get(getLandInfo, { params })
      if (res.status === 100) {
        this.form = res.data
        const {
          contractAreaHectare,
          contractAreaHao,
          contractAreaLi,
          contractAreaFen,
          contractAreaMu,
          madeByAreaHectare,
          madeByAreaHao,
          madeByAreaMu,
          madeByAreaFen,
          madeByAreaLi,
          beLocatedEast,
          beLocatedWest,
          beLocatedSouth,
          beLocatedNorth
        } = res.data
        if (this.pageType === 'check') {
          // 承包
          const Hectare = contractAreaHectare ? Number(contractAreaHectare) + ' 公顷 ' : ''
          const Mu = contractAreaMu ? Number(contractAreaMu) + ' 亩 ' : ''
          const Fen = contractAreaFen ? Number(contractAreaFen) + ' 分 ' : ''
          const Li = contractAreaLi ? Number(contractAreaLi) + ' 厘 ' : ''
          const Hao = contractAreaHao ? Number(contractAreaHao) + ' 毫 ' : ''
          this.form.contractArea = Hectare + Mu + Fen + Li + Hao
          // 土地信息
          const _Hectare = madeByAreaHectare ? Number(madeByAreaHectare) + ' 公顷 ' : ''
          const _Mu = madeByAreaMu ? Number(madeByAreaMu) + ' 亩 ' : ''
          const _Fen = madeByAreaFen ? Number(madeByAreaFen) + ' 分 ' : ''
          const _Li = madeByAreaLi ? Number(madeByAreaLi) + ' 厘 ' : ''
          const _Hao = madeByAreaHao ? Number(madeByAreaHao) + ' 毫 ' : ''
          this.form.madeByArea = _Hectare + _Mu + _Fen + _Li + _Hao
          // 坐落，四至
          const East = beLocatedEast ? '东至 ' + beLocatedEast + ' , ' : ''
          const West = beLocatedWest ? '西至 ' + beLocatedWest + ' , ' : ''
          const South = beLocatedSouth ? '南至 ' + beLocatedSouth + ' , ' : ''
          const North = beLocatedNorth ? '北至 ' + beLocatedNorth + ' , ' : ''
          this.form.beLocated = East + West + South + North
        }
      }
    },
    located (rule, value, callback) {
      if (!this.form.beLocatedEast) {
        callback(new Error('请输入坐落（东至）'))
      } else if (!this.form.beLocatedWest) {
        callback(new Error('请输入坐落（西至）'))
      } else if (!this.form.beLocatedSouth) {
        callback(new Error('请输入坐落（南至）'))
      } else if (!this.form.beLocatedNorth) {
        callback(new Error('请输入坐落（北至）'))
      } else {
        callback()
      }
    },
    madeArea (rule, value, callback) {
      if (!this.form.madeByAreaHectare && !this.form.madeByAreaMu && !this.form.madeByAreaFen && !this.form.madeByAreaLi && !this.form.madeByAreaHao) {
        callback(new Error('请输入确权面积'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.landInfoForm-container {
  .area-item {
    margin-right: 10px;
  }
  .area-ruler {
    margin: 0 10px;
  }
  .area-bot {
    margin-bottom: 10px;
  }
  .check-wrapper {
    margin: 0 20px;
    ::v-deep .text {
      text-align: left;
    }
    .check-back {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
</style>
